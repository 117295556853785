<template>
  <div class="guide-invoice">
    <ValidationObserver ref="form">
      <b-row>
        <b-col cols="12" lg="9">
          <b-card>
            <!--add header card to serialNum, Date-->
            <b-card-header>
              <div>
                <div class="d-flex mb-2 align-items-center">
                  <h3 class="text-primary pr-2">
                    <strong> E-LEARNING</strong>
                  </h3>
                </div>
              </div>
              <div>
                <ek-input-text
                  v-if="invoicid == 0"
                  readonly
                  name="number"
                  label="رقم الفاتورة"
                  :value="teacherUnpaid.serialNumber"
                >
                </ek-input-text>
                <ek-input-text
                  v-if="invoicid != 0"
                  readonly
                  name="number"
                  label="رقم الفاتورة"
                  v-model="teacherInvoiceDto.serialNumber"
                >
                </ek-input-text>
                <ek-input-text
                  name="dateInvoice"
                  label="تاريخ الفاتورة"
                  :value="formDate(teacherInvoiceDto.dateCreated)"
                >
                </ek-input-text>
              </div>
            </b-card-header>
            <hr />
            <!--add Answers -->
            <b-card-body>
              <!--if no code Unpaid-->
              <div
                class="d-flex justify-content-center"
                v-if="teacherUnpaid.units == ''"
              >
                <h4>لا يوجد وحدات</h4>
              </div>
              <!-- All Code is unPaid-->
              <b-row v-for="(unit, index) in teacherUnpaid.units" :key="index">
                <b-col lg="1" cols="1" v-if="invoicid == 0">
                  <b-button variant="wight" @click="deleteUnit(index)">
                    <svg
                      style="cursor: pointer"
                      width="25"
                      height="25"
                      viewBox="0 0 19 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.50016 17.4163C13.8724 17.4163 17.4168 13.8719 17.4168 9.49968C17.4168 5.12742 13.8724 1.58301 9.50016 1.58301C5.12791 1.58301 1.5835 5.12742 1.5835 9.49968C1.5835 13.8719 5.12791 17.4163 9.50016 17.4163Z"
                        stroke="#616060"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.875 7.125L7.125 11.875"
                        stroke="#616060"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.125 7.125L11.875 11.875"
                        stroke="#616060"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </b-button>
                </b-col>
                <b-col lg="3" cols="3">
                  <b-form-group
                    v-if="invoicid == 0"
                    :disabled="!ready"
                    name="sub"
                    label="المادة"
                  >
                    <b-form-input
                      name="code"
                      id="item-name"
                      type="text"
                      plaintext
                      v-model="unit.subjectName"
                    />
                  </b-form-group>
                </b-col>

                <b-col lg="2" cols="2">
                  <b-form-group
                    v-if="invoicid == 0"
                    :disabled="!ready"
                    name="unit"
                    label="الوحدة"
                  >
                    <b-form-input name="date" plaintext v-model="unit.unitName" />
                  </b-form-group>
                </b-col>
                <b-col
                  lg="3"
                  cols="6"
                  v-for="item in unit.paids"
                  :key="item.id"
                  class="d-block"
                >
                  <b-row>
                    <b-col lg="12" cols="12">
                      <b-col lg="12" cols="12">
                        <b-form-group
                          :disabled="!ready"
                          v-if="invoicid == 0"
                          name="date"
                          label="عدد الإشتراكات"
                        >
                          <b-form-input name="date" v-model="item.countSubscription" />
                        </b-form-group>
                      </b-col>
                      <b-col lg="12" cols="2">
                        <b-form-group
                          :disabled="!ready"
                          v-if="invoicid == 0"
                          name="date"
                          label="النسبة "
                        >
                          <b-form-input name="date" v-model="item.rate" />
                        </b-form-group>
                      </b-col>
                      <b-col lg="12" cols="2">
                        <b-form-group v-if="invoicid == 0" name="cost" label="الكلفة">
                          <b-form-input name="cost" plaintext v-model="item.cost" />
                        </b-form-group>
                      </b-col>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <!--unit is Paid-->
              <b-row v-for="unit in teacherInvoiceDto.units" :key="unit.id">
                <!-- {{unit.paids}} -->

                <b-col lg="3" cols="3">
                  <b-form-group
                    v-if="invoicid != 0"
                    :disabled="!ready"
                    name="sub"
                    label="المادة"
                  >
                    <b-form-input
                      name="code"
                      id="item-name"
                      type="text"
                      plaintext
                      v-model="unit.subjectName"
                    />
                  </b-form-group>
                </b-col>

                <b-col lg="3" cols="3">
                  <b-form-group
                    v-if="invoicid != 0"
                    :disabled="!ready"
                    name="unit"
                    label="الوحدة"
                  >
                    <b-form-input name="date" plaintext v-model="unit.unitName" />
                  </b-form-group>
                </b-col>
                <b-col
                  lg="3"
                  cols="6"
                  v-for="item in unit.paids"
                  :key="item.id"
                  class="d-block"
                >
                  <b-row>
                    <b-col lg="12" cols="12">
                      <b-col lg="12" cols="12">
                        <b-form-group
                          :disabled="!ready"
                          v-if="invoicid != 0"
                          name="date"
                          label="عدد الإشتراكات"
                        >
                          <b-form-input name="date" v-model="item.countSubscription" />
                        </b-form-group>
                      </b-col>
                      <b-col lg="12" cols="2">
                        <b-form-group
                          :disabled="!ready"
                          v-if="invoicid != 0"
                          name="date"
                          label="النسبة "
                        >
                          <b-form-input name="date" v-model="item.rate" />
                        </b-form-group>
                      </b-col>
                      <b-col lg="12" cols="2">
                        <b-form-group v-if="invoicid != 0" name="cost" label="الكلفة">
                          <b-form-input name="cost" plaintext v-model="item.cost" />
                        </b-form-group>
                      </b-col>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card-body>
            <b-card-footer>
              <!--add footer to paid , note-->
              <b-row>
                <b-col lg="8" cols="12">
                  <label>الملاحظات</label>
                  <ek-input-textarea
                    id="note"
                    rows="6"
                    v-model="teacherInvoiceDto.note"
                    name="note"
                    placeholder="اضف الملاحظات"
                  >
                  </ek-input-textarea>
                </b-col>
                <b-col lg="4" cols="12">
                  <b-col cols="12">
                    <ul class="list-unstyled p-0 m-0" v-if="teacherUnpaid.units.length">
                      <li
                        class="d-flex pb-1 pt-2 justify-content-between align-items-center"
                      >
                        <div>عدد الاشتراكات</div>
                        <div v-if="invoicid == 0">
                          {{
                            teacherUnpaid.units.reduce((accumulator, item) => {
                              const productSum = item.paids.reduce(
                                (productAcc, product) => {
                                  return productAcc + product.countSubscription;
                                },
                                0
                              );
                              return accumulator + productSum;
                            }, 0)
                          }}
                        </div>
                        <div v-if="invoicid != 0">
                          {{ teacherInvoiceDto.totalSubscription }}
                        </div>
                      </li>
                      <li class="d-flex pt-1 justify-content-between align-items-center">
                        <div>الكلفة</div>
                        <div v-if="invoicid == 0">
                          {{
                            teacherUnpaid.units.reduce((accumulator, item) => {
                              const productSum = item.paids.reduce(
                                (productAcc, product) => {
                                  return productAcc + product.cost;
                                },
                                0
                              );
                              return accumulator + productSum;
                            }, 0)
                          }}
                        </div>

                        <div v-if="invoicid != 0">
                          {{ teacherInvoiceDto.totalCost }}
                        </div>
                      </li>
                    </ul>
                  </b-col>
                  <b-col cols="12">
                    <hr class="d-none d-lg-block" />
                  </b-col>
                  <b-col cols="12">
                    <ul class="list-unstyled p-0">
                      <li class="d-flex justify-content-between align-items-center">
                        <div>المستحق للإستاذ</div>
                        <div v-if="invoicid == 0">
                          {{
                            teacherUnpaid.units.reduce((accumulator, item) => {
                              const productSum = item.paids.reduce(
                                (productAcc, product) => {
                                  return productAcc + product.teacherPaid;
                                },
                                0
                              );
                              return accumulator + productSum;
                            }, 0)
                          }}
                        </div>

                        <div v-if="invoicid != 0">
                          {{ teacherInvoiceDto.teacherPaid }}
                        </div>
                      </li>
                    </ul>
                    <div>
                      <b-input-group class="d-flex">
                        <b-input-group-prepend is-text> المدفوع </b-input-group-prepend>
                        <b-form-input
                          placeholder=" المبلغ المدفوع"
                          type="number"
                          v-model="teacherInvoiceDto.paid"
                        >
                        </b-form-input>
                        <b-input-group-append is-text> ل.س </b-input-group-append>
                      </b-input-group>
                    </div>
                  </b-col>
                </b-col>
              </b-row>
            </b-card-footer>
          </b-card>
        </b-col>
        <b-col cols="12" lg="3" class="print">
          <!--add button to save , print-->
          <b-card>
            <b-button v-if="invoicid == 0" variant="primary" block @click="AddInvoice">
              حفظ
            </b-button>
            <b-button v-if="invoicid == 0" variant="outline-primary" block @click="print">
              طباعة
            </b-button>
            <b-button
              v-if="invoicid == 0"
              variant="outline-primary"
              block
              @click="$router.go(-1)"
              >عودة
            </b-button>
            <b-button v-if="invoicid != 0" variant="primary" block @click="print">
              طباعة
            </b-button>
            <b-button
              v-if="invoicid != 0"
              variant="outline-primary"
              block
              @click="deleteInvoice(teacherInvoiceDto.id)"
            >
              حذف
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </ValidationObserver>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { BToast } from "bootstrap-vue";

export default {
  computed: {
    ...mapState({
      // teacherUnpaid: (state) => state.invoiceTeacher.teacherUnpaid,
      teachersDetails: (state) => state.invoiceTeacher.teachersDetails,
    }),
    ...mapGetters(["teacherUnpaid"]),
  },
  props: ["invoicid", "id"],
  data() {
    return {
      teacherInvoiceDto: {
        id: "",
        teacherId: "",
        serialNumber: "",
        dateCreated: Date.now(),
        units: [
          {
            id: "",
            unitName: "",
            subjectName: "",
            paids: [{ cost: 0, countSubscription: "", rate: "" }],
          },
        ],
        unitIds: [],
        note: "",
        totalSubscription: "",
        totalCost: "",
        balanceDue: "",
        paid: "",
      },
      teacherList: [],
    };
  },
  methods: {
    ...mapActions([
      "getTeacherInvoiceDetails",
      "getUnpaidTeacherList",
      "addTeacher",
      "deleteInvoice",
    ]),
    print() {
      window.print();
    },
    formDate(dateCreated) {
      let date = `${new Date(dateCreated).toLocaleDateString()}`;
      return date;
    },
    deleteUnit(index) {
      this.teacherUnpaid.splice(index, 1);
      this.teacherInvoiceDto.unitIds.splice(index, 1);
    },
    AddInvoice() {
      this.$refs.form.validate().then((succ) => {
        if (succ && this.teacherUnpaid.units.length > 0) {
          this.addTeacher(this.teacherInvoiceDto).then(
            this.teacherList.push(this.teacherInvoiceDto)
          );
        } else {
          const bToast = new BToast();
          bToast.$bvToast.toast(" لا يمكنك إضافة فاتورة ... لا يوجد وحدات", {
            title: "  لا يوجد وحدات ",
            variant: "danger",
            toaster: "b-toaster-bottom-right",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        }
      });
    },
  },
  created() {
    this.teacherInvoiceDto.teacherId = this.id;
    if (this.invoicid != 0) {
      this.getTeacherInvoiceDetails(this.invoicid).then((res) => {
        this.teacherInvoiceDto = { ...res };
      });
    } else {
      this.getUnpaidTeacherList(this.id).then((res) => {
        this.teacherInvoiceDto.unitIds = res.units.map((el) => el.id);
      });
    }
  },
};
</script>
<style>
@media print {
  .print {
    display: none;
  }
  .main-menu {
    display: none !important;
  }
  .app-content {
    margin: 0 !important;
  }
}
</style>
